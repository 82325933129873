.desktop-dropdown {
  display: none;
}

.mobile-dropdown {
  display: block;
}

.opaque {
  opacity: 1;
}


.home-hero-slide__figure--loading {
  width: 40vw;
  max-width: 640px;
  margin-top: 120px;
  float: right;
  shape-image-threshold: 0.5;
  shape-rendering: crispEdges;
  shape-margin: 1em;

  @media (max-width: 511px) {
    width: 65vw;
    margin-top: 65px;
    margin-right: -10vw;
  }

  @media (min-width: 768px) {
    margin-top: 120px;
  }

  @media (min-width: 992px) {
    width: 44vw;
  }

  @media (min-width: 1060px) {
    margin-top: 95px;
    width: 50vw;
  }

  @media (min-width: 1200px) {
    width: 44.4vw;
    margin-right: 0;
  }

  @media (min-width: 1440px) {
    width: 645px;
    margin-right: 28px;
  }
}

.dropdown-wrapper .wm-field-input.wm-field-input {

  select { // .dropdown-wrapper .wm-field-input.wm-field-input select
    appearance: auto;
    color: black;
  }

  &::after {
    display: none;
  }

  & > span {
    opacity: 0 !important;
  }
}

.info-toggle-select--mobile.dropdown-wrapper select {
  appearance: auto !important;
}

.embed-lightbox-toggle {
  display: none;
}

.embed-lightbox {
  position: static;
  background: none;
  opacity: 1;
  padding-bottom: 40px;

  @media (min-width: 920px) {
    margin-bottom: 120px;
  }
}

.embed-lightbox button {
  display: none;
}

.hr-video {
  margin: 50px 0;
  transform: none;
  width: 100%;

  & > * {
    display: none;
  }
}